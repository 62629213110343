html,body{
  scroll-behavior: smooth;
}
::-webkit-scrollbar {
  width: 5px;
  background-color: #051B34;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey;  */
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #7F0194; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #006DD1; 
}
.height-center {
  display: grid;
  place-items: center;
}
.thanks-message-cont
{
  position:absolute;
  width: 100%;
  bottom: 3vh;
}
.thanks-message-head
{
  text-align: center;
  font-size:3rem;
  font-family: 'Martel Sans', sans-serif;
  font-weight: bold;
  margin: 0;
}
.thanks-message-body{

  text-align: center;
  font-size:2rem;
  font-family: 'Martel Sans', sans-serif;
  margin: 0;
  font-weight: 300 !important;
}
.main-page
{
  background-color: #051B34;
  overflow-x: hidden;
  z-index: 0;
}
.wid-center
{
  width: 85%;
  margin-left:auto;
  margin-right:auto;
}
.wid-90
{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.form-submit-cont
{
  display: block;
  position: fixed;
  height: 100vh;
  width:100vw;
  z-index: +100;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.6);
}
.form-submit-image-pc{
  height: 80vh;
  width: 70vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top:10vh;
  animation: move 1s ease-in-out;
  transform: scale(1);
}
@keyframes mymove {
  from {transform: scale(0);}
  to {transform: scale(1);}
}
.close-btn
{    
  position: absolute;
  right: 10vw;
  top: 2vw;
  width: 3rem;
}
.float-right
{
  float: right;
}

.block-50 
{
  flex: 1;

}

.clr-white
{
  color :white;
}
.clr-green
{
  color:#6AE149;
}
.navbar{
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left:3rem;
}
.nav-tags
{
  display: inline-block;
  float: right;
  font-size: 1.3rem;
  position: relative;
  bottom: 3vh;
  opacity:0.6;
}
.nav-tags:hover
{
  opacity: 1;
}
.contact-us-link
{
  color:white;
 cursor: pointer;
}
.header
{
  background-image:url(./assets2/headerBack.png);
  background-repeat:no-repeat;
  background-size: cover;
  min-height:102vh;
}
@media only screen and (min-width: 1200px) {
  .nav-logo
  {
    width: 15%;
  }
  .scroll-down
  {
    position: absolute;
    /* display: block; */
    /* margin-left: auto; */
    /* margin-right: auto; */
    width: 5vw;
    height: 10vh;
    transform: scale(1);
    top: 95%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;

  }
  .ring
  {
    position: absolute;
    width: 50%;
    /* height: 42vh; */
    margin-left: auto;
    top: 35vh;
    left: 24vw;
    margin-right: auto;

  }
  .head1
  {
    display:block;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 29vh;
  }
  .head2
  {
    display: block;
    width: 15%;
    margin-left: auto;
    margin-right: auto;
  }
}

.video
{
  height: 80vh;
  width: 100vw;
  overflow: hidden;
  /* background-image: url(./assets2/videoBack.png);
  background-repeat: no-repeat;
  background-size: cover; */

}
.video-image {
  position: absolute;
  top: 102vh;
  height: 80vh;
  width: 100%;
  z-index: 10;
}
.react-video-player{
  position: relative;
  width: 100%;
  transform: scale(1.5);
  z-index: 20;
}
.form-cont{
  padding-top:5rem;
  display: grid;
  align-items: center;
  min-height: 100vh;
}
.form
{
  display:flex;
  min-height: 80vh;
  /* margin-top: 5rem; */
  margin-bottom: 5rem;
  /* background-color: #041629; */
  /* border: 5px solid; */
  /* border-image-slice: 1; */
  /* border-image-source: linear-gradient(to bottom, #006DD1, #7F0194 , #FF57B2 ,#247CCD); */
  /* background-image: url(./assets2/formBack.png); */
  /* background-size:cover; */
  /* background-repeat: no-repeat; */
  /* box-shadow: 0px 0px 60px #FF57B2; */
}

.form-back1-img 
{
  position: absolute;
  margin-top: 20vh;
}
.main-form
{
  background-color: #041629;
  border: 3px solid #247CCD;
  border-radius:20px;
  box-shadow: 0px 0px 60px #FF57B2;
}
.form-components-container
{
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding-bottom: 7vh;
  justify-content: center;
  background-color: #25538873;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.form-heading
{
  font-family: 'Martel Sans', sans-serif;
  font-size: 3vw;
  /* margin-top:15rem; */
  line-height: 4rem;
  z-index: +100;
}
.form-left-block
{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.respond-line
{
  margin-top:1rem;
  margin-bottom:0.5vw;
  margin-left: 0.5vw;
}
.form-submit-line
{
  margin-top:0;
  margin-bottom:1.5rem;
  margin-left: 0.5rem;
  font-size: larger;
}
.form-butn-container
{
  /* display: flex; */
  width:80%;
  margin-left: auto;
  margin-right: auto;
}
.butt-blue 
{
  /* flex: 1; */
  display: block;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  background: linear-gradient(to bottom, #089CCE,#247CCD);
  font-family: 'Martel Sans', sans-serif;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 50px;
  /* margin-right: 2rem; */
  cursor: pointer;
  transition: 0.5s;
  transform: scale(1);
}
.butt-blue:hover
{
  /* width: 85%; */
  
  transform: scale(1.2);
  animation: buttHoverEffect 0.5s ;
  animation-timing-function: ease-in-out;
}
@keyframes buttHoverEffect {
  from {
    /* width: 75%; */
    
  transform: scale(1);
  }
  to {
    /* width: 85%; */
    
  transform: scale(1.2);
  }
}
.butt-whatsapp{
  /* flex: 1; */
  display: block;
  width: 75%;
  margin-top: 3vh;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Martel Sans', sans-serif;
  background-color: transparent;
  border: 1px solid #6AE149;
  color: #6AE149;
  padding: 1rem 2rem;
  padding-top: 0;
  border-radius: 50px;
  cursor: pointer;
  transition: 0.5s;
  transform: scale(1);
}
.butt-whatsapp:hover
{
  transform: scale(1.2);
  animation: buttHoverEffect 0.5s ;
  animation-timing-function: ease-in-out;
}
.imput-feild-container
{
  /* padding-top: 5rem; */
}
.input-feild
{
  display: block;
  width: 80%;
  margin-left:auto;
  margin-right:auto;
  color: white;
  background-color: #041425c9;
  border-radius: 50px;
  border: none;
  /* border-bottom: 2px solid grey; */
  padding: 1rem 2rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-size: large;
}
.textarea-feild
{
  display: block;
  width: 80%;
  height: 16vh;
  margin-left:auto;
  margin-right:auto;
  color: white;
  background-color: #041425c9;
  border-radius: 20px;
  border: none;
  /* border-bottom: 2px solid grey; */
  padding: 1rem 2rem;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  font-size: large;
  font-family: Arial, Helvetica, sans-serif;
  resize: none;
}
.whatsapp-container
{
  display:flex;
  margin-left: 0.5vw;
}

.whatsapp-icon {
  width: 2rem;
  height: 2rem;
  position: relative;
  top: 0.8rem;
  margin-right: 1rem;
}

.projects
{
  min-height: 80vh;

}
.project-head
{
  display: block;
  width: 29%;
  margin-bottom: 2rem;
}
.indi-project
{
  width: 47%;
  margin-left: 1rem;
  margin-right: 1rem;
  display: inline-block;
  margin-bottom: 3rem;
}
/* .indi-project:hover
{
  width: 49%;
  margin-left: 0rem;
  margin-right: 0rem;
  display: inline-block;
  margin-bottom: 1.5rem;
} */
.project-image-container
{
  overflow: hidden;
  width: 100%;
}
.project-image
{
  width: 100%;
  transform: scale(1);
  transition: 1s;
}
.project-image:hover
{
  transform: scale(1.2);
  /* animation: mymove 1s ; */
  /* animation-timing-function: ease-in-out; */
}
@keyframes mymove {
  from {transform: scale(1);}
  to {transform: scale(1.2);}
}
.project-tag
{
  color: white;
  font-family: 'Martel Sans', sans-serif;
  padding-bottom: 2rem;
  border-bottom: 4px solid #138E9D;
  font-size: large;
}
.design-tools
{
  display:flex;
}
.design-head-img
{
  width: 60%;
}
.design-content-container
{
  padding-top: 8rem;
}
.design-desc
{
  margin-top: 2rem;
  margin-bottom:6rem;
  width: 55%;
  font-size: x-large;
}
.design-content-img
{
}
.banner1
{
  transform: scale(1);
  transition: 1s;
}
.banner1:hover
{
  transform: scale(1.1);
  /* animation: mymove 1s ; */
  /* animation-timing-function: ease-in-out; */
}
.case-data-container
{
  padding-top: 10rem;
}
.case-img{
  width: 50%;
}
.case-heading
{
  font-family: 'Martel Sans', sans-serif;
  color: white;
  font-size: 2.5vw;
  width: 80%;
  line-height: 3rem;
}
.case-para
{
  display: block;
  width: 50%;
  margin-bottom: 2rem;
  font-size:large;
}
.caseCont{
  background-image: url(./assets/caseBack.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 90vh;
  padding-top:8rem;
}
.case-study
{
  display:flex;
}
.case-heading
{

}

.leaders
{

}
.leaders-head-img
{
  width: 45%
}
.leader-container
{
  /* display:flex; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.indi-leader
{
  flex: 1;
  margin-left: 1rem;
  margin-right: 1rem;
  overflow: hidden;
}
.indi-leader-img
{
  width: 100%;
  margin-top: 3vh;
}
/* .indi-leader-img:hover
{
  transform: scale(1.2);
  animation: mymove 1s ;
  animation-timing-function: ease-in-out;
} */

.form-mobile{
  display: none
}
.foot-message
{
  margin-top:10vh;
}
.foot-message-cont
{
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}
.foot-mess
{
  display: inline-block;
  font-family: 'Martel Sans', sans-serif;
  font-size: 3vw;
  margin-right: 3rem;
}
.contact-us-butt
{
  position: relative;
  bottom: 1rem;

}
.footer
{
  background-color: black;
  padding-bottom:3rem;
  margin-top:5rem;
}
.footer-mobile{
  display: none;
}
.foot-cont
{
  display: flex;

}
.footer-main-img
{
  width: 40%;
  margin-top: 5rem;
}
.foot-head
{
  margin-top:0;
  margin-left: 0.5rem;
}
.foot-con-head
{
  margin-top: 2rem;
  margin-bottom: 0;
  font-family: 'Martel Sans', monospace;
}
.foot-con-mail
{
  font-family: 'Martel Sans', monospace;
  margin: 0;
}
.foot-data-cont
{
  /* margin-right: 5rem; */
  width: 58%;
}
.about-us
{
  font-family: 'Martel Sans', monospace;
  color: white;
  margin-top: 9vh;
  margin-bottom: 0;
  font-size: 1.5rem;
}
.about-us-desc
{
  line-height: 2rem;
  font-family: 'Raleway', sans-serif;
  color: white;
  font-size: 1rem;
}
.social-icon
{
  margin-right: 2rem;
}
.hyperLink
{
  color: #089CCE;
  cursor: pointer;
  text-decoration: underline;
}
@media only screen and (max-width: 1200px) {
  .thanks-message-cont
  {
    position:absolute;
    width: 100%;
    bottom: 30vh;
  }
  .thanks-message-head
  {
    text-align: center;
    font-size:2rem;
    font-family: 'Martel Sans', sans-serif;
    font-weight: bold;
    margin: 0;
  }
  .thanks-message-body{
    text-align: center;
    font-size: 1rem;
    font-family: 'Martel Sans', sans-serif;
    margin: 0;
    font-weight: 300 !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .nav-tags{
    display: none;
  }
  .scroll-down
  {
    display: none;
  }
  .wid-90
  {
    width: 90%;
  }
  .navbar
  {padding-top: 1.5rem;
    padding-bottom: 2rem;
    padding-left:1rem;
    margin-bottom: 0rem;
  }
  .nav-logo
  {
    width: 40%;
  }
  .header{
    min-height: 60vh
  }
  .ring
  {
    position: absolute;
    width: 86vw;
    height: 19vh;
    left: 5vw;
    top: 24vh;
  }
  .head1
  {
    width: 85%;
    margin-top: 18vh;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .head2
  {
    display: block;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
  }
  .video
  {
    height: 30vh;
    width: 100%;
  }
  
  .video-image {
    position: absolute;
    top: 60vh;
    height: 30vh;
    width: 100%;
    z-index: 10;
  }
  .projects
  {
    display: block;
  }
  .indi-project
  {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    margin-bottom: 3rem;
  }
  /* .indi-project:hover
  {
    width: 100%;
    margin-left: 0rem;
    margin-right: 0rem;
    display: inline-block;
    margin-bottom: 3rem;
    transform: scale(1.05);
  } */
  .float-right
  {
    float: left
  }
  .form
  {
    display: none;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }

  .projects
  {
    margin-top: 3rem;
  }
  .project-head
  {
    width: 85%;
  }
  .project-tag
  {
    padding-bottom: 1rem;
  }
  .design-tools
  {
    display: block;
  }
  .design-head-img
  {
    width: 90%;
  }
  
  .design-desc
  {
    width: 80%;
    margin-top: 1rem;
    margin-bottom:2rem;
  }
  .design-content-img
  {
    width: 100%;
  }
  .banner1
  {
    width: 100%;
  }
  .caseCont{
    padding-top: 0rem;
    min-height: 30vh;
  }
  .case-data-container
  {
    padding: 0.5rem;
  }
  .case-img
  {
    width: 77%;
  }
  .case-heading
  {
    line-height: 1.5rem;
    font-size: x-large;
    width: 100%;
    font-weight: bold;
  }
  .case-para
  {
    width:100%
  }
  .case-study
  {
    display: block;
  }
  .banner2
  {
    width: 100%;
    margin-top: 2rem;

    transform: scale(1.1);
    transition: 1s;
  }
  .banner2:hover
  {
    transform: scale(1.2);
    /* animation: mymove 1s ; */
    /* animation-timing-function: ease-in-out; */
  }
  .leader-container
  {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .leaders-head-img
  {
    width: 100%;
    margin-left: auto;
    margin-right: auto
  }
  .indi-leader
  {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 3rem; */
  }
  
  .form-mobile{
    display: block;
    margin-top:5rem;
    background-image: url(./assets/caseBack.png);
    background-repeat: no-repeat;
    background-size: cover;
  }
  .form-submit-image-pc {
    height: 85vh;
    width: 90vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 9vh;
    animation: move 1s ease-in-out;
    transform: scale(1);
  }
  .close-btn {
    position: absolute;
    right: 1vw;
    top: 3vw;
    width: 3rem;
  }


  .form-heading
  {
    font-size: 6vw;
    margin-top: 2vw;
    text-align: center;
    line-height:2rem;
  }
  .input-feild
  {
    background-color: #082444;
    border: none;
    color: white;
    border-radius: 50px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: large;
  }
  .form-submit
  {
    width: 100%;
  }
  .respond-line{
    color: #0A8BBF;
    text-align: center;
  }
  .whatsapp-container
  {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.5vh;
  }
  .foot-cont{
    display: block;
  }
  .foot-con-head
  {
    margin-top: 1rem;
  }
  .foot-mess
  {display: block;
    text-align: center;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.8rem;
  }
  .contact-us-butt
  {
    display: block;
    text-align: center;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
  }
  .footer
  {
    display: none;
    overflow: hidden;
    background-color: #03162B;
  }
  .footer-mobile-img
  {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .about-us-mobile
  {
    
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Martel Sans', monospace;
    color: white;
    margin-top: 4vh;
    margin-bottom: 4vh;
    margin-bottom: 0;
    font-size: 1.5rem;
  }
  .about-us-desc-mobile
  {
    text-align: center;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    line-height: 2rem;
    font-family: 'Raleway', sans-serif;
    color: white;
    font-size: 1rem;
  }
  .footer-mobile
  {
    display: block;
    overflow: hidden;
    background-color: #03162B;
    padding-top: 5vh;
    padding-bottom: 8vh;
  }
  .footer-main-img
  {
    width: 100%;
  }
  .foot-head
  {
    text-align: center;
  }
  .block-footer
  {
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .social-icon-container
  {
    width:90%;
    margin-left: auto !important;
    margin-right: auto !important;
    display:flex;
  }
  .social-icon
  {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    flex:1;
  }
  .foot-data-cont
  {
    margin-right: 0;
  }
  .foot-con-head
  {
    text-align: center;
  }
  .foot-con-mail
  {
    text-align: center;
  }
  .block-50
  {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .form-cont{
    min-height: 0vh;
    padding-top: 0vh;
  }
}